/* eslint-disable react/display-name */
import React from 'react';
import styled from 'styled-components';

// import * as css from './drawer-image-text-2up-with-callouts.css';
import { Grid } from 'src/system2/grid';
import { Stack } from 'src/system2/stack';
import { Box } from 'src/system2/box';
import { FixedImg, FluidImg } from 'src/embeds/image/image-dato';
import * as sys from 'src/system';
import * as css from './drawer-image-text-2up-with-callouts.css';

export default function Drawer({ heading, contentNode, callouts, image }) {
  const wrapWidth = 1100;
  return (
    <Box as="section" sx={{ my: { _: 48, ml: 96 } }} className="reset2">
      <div css={[sys.wrapLast, sys.frame]}>
        <Stack sx={{ gap: { _: 48, m: 64 } }}>
          {callouts.slice(0, 2).map(({ preContentNode, media }, i) => (
            <Grid
              key={media.url}
              className="ai-center"
              sx={{
                ai: 'center',
                gapY: 16,
                gapX: 32,
                mx: 'auto',
                cols: { _: 8 },
              }}
            >
              <Box
                sx={{
                  span: { _: 7, s: 5, m: 3 },
                  order: { _: 0, m: i },
                  start: i == 1 ? { _: 2, s: 4, m: 6 } : undefined,
                }}
              >
                <FluidImg data={media} maxWidth={800} style={{ maxWidth: '100%' }} />
              </Box>
              <Stack
                as="article"
                sx={{
                  gap: { _: 16 },
                  span: { _: 8, m: 5, l: 4 },
                  start: i == 1 ? { _: 1, l: 2 } : undefined,
                }}
              >
                <h3 css={css.majorHeading}>Lorem ipsum dolor sit amet consectetur adipisicing elit.</h3>
                <div
                  css={css.majorText}
                  dangerouslySetInnerHTML={{ __html: preContentNode.childMarkdownRemark.html }}
                />
              </Stack>
            </Grid>
          ))}
          <Grid
            sx={{
              cols: { _: 1, m: 3 },
              gapY: { _: 32, m: 48 },
              gapX: { m: 64, l: 128 },
              maxW: wrapWidth,
              mx: 'auto',
            }}
          >
            {callouts.slice(2, 5).map(({ heading, content, media }) => (
              <Stack
                as="aside"
                sx={{ gap: { _: 20 }, ta: { _: 'center', m: 'left' }, maxW: 500, mx: 'auto', jc: 'center' }}
                key={heading}
              >
                <Box sx={{ w: 'auto', mx: { _: 'auto', m: 0 } }}>
                  <FixedImg data={media} width={64} height={64} />
                </Box>
                <Stack gap={10}>
                  <h3 css={css.minorHeading}>{heading}</h3>
                  <p css={css.minorText}>{content}</p>
                </Stack>
              </Stack>
            ))}
          </Grid>
        </Stack>
      </div>
    </Box>
  );
}
