import { css } from 'styled-components';

export * from '../updates/common.css';
import { schemes } from '../../../tabula.config';

export const heading = css``;

export const majorHeading = css`
  font-size: var(--fz);
  line-height: calc(var(--fz) + 10px);
  --fz: 22px;
  @media (min-width: 24em) {
    --fz: 24px;
  }
  @media (min-width: 36em) {
    --fz: 26px;
  }
  /* @media (min-width: 48em) {
    --fz: 20px;
  } */
  @media (min-width: 60em) {
    --fz: 30px;
  }
`;

export const majorText = css`
  color: #707a88;
  font-size: var(--fz);
  line-height: calc(var(--fz) + 10px);
  > * + * {
    margin-top: 0.75em !important;
  }
  --fz: 18px;
  @media (min-width: 24em) {
    --fz: 18px;
  }
  @media (min-width: 36em) {
    --fz: 20px;
  }
  /* @media (min-width: 48em) {
    --fz: 20px;
  } */
  @media (min-width: 60em) {
    --fz: 22px;
  }
`;

export const minorHeading = css`
  /* color: #707a88; */
  font-size: var(--fz);
  line-height: calc(var(--fz) + 8px);
  --fz: 16px;
  @media (min-width: 24em) {
    --fz: 18px;
  }
  @media (min-width: 48em) {
    --fz: 20px;
  }
`;

export const minorText = css`
  color: #707a88;
  font-size: var(--fz);
  line-height: calc(var(--fz) + 8px);
  --fz: 14px;
  @media (min-width: 24em) {
    --fz: 16px;
  }
  @media (min-width: 48em) {
    --fz: 18px;
  }
`;
